import { images } from '../constants';

const team = [
    {
        id: 1,
        Image: images.team1,
        name: 'scarlett newman',
        title: 'Manager',
    },
    {
        id: 2,
        Image: images.team2,
        name: 'Farwa Khan',
        title: 'Assistent Manager',
    },
    {
        id: 3,
        Image: images.team3,
        name: 'Scarlet Brown',
        title: 'florist',
    },
    {
        id: 4,
        Image: images.team4,
        name: 'Kaili White',
        title: 'florist',
    },
 
];

export default team;