const orderlist = [
    { 
        id: 1,
        orderID: '#12345',
        date: 'jan 1, 2021',
        total: '$50.00',
        status: 'completed',
    },
    {
        id: 2,
        orderID: '#23456',
        date: 'jan 1, 2021',
        total: '$150.00',
        status: 'completed',
    },
    {
        id: 3,
        orderID: '#34567',
        date: 'jan 1, 2021',
        total: '$200.00',
        status: 'cancelled',
    },
    {
        id: 4,
        orderID: '#45678',
        date: 'jan 1, 2021',
        total: '$350.00',
        status: 'processing',
    },
    {
        id: 5,
        orderID: '#56789',
        date: 'jan 1, 2021',
        total: '$50.00',
        status: 'processing',
    },
];

export default orderlist;