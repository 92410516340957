import React from 'react';
import './About.css';

import { Heading, Button } from '../../../components';
import { images } from '../../../constants';

import ReactPlayer from 'react-player';


const About = () => {  
  return (
    <section className='about' id='about'>

      <Heading title={'about us'} span={'know about us'} />

      <div className='box-container'>

        <div className='video-container'>
            <ReactPlayer 
            url={images.about} 
            playing={true}
            loop={true}
            muted={true}
            width='100%'
            height='40rem'/>
            <h3>5+ years experience</h3>
        </div>

        <div class="content">
          <h2>You bring the love. We’ll bring the flowers.</h2>
          <h5>We have the perfect floral arrangements to brighten someone’s day because
              We only sell flowers that we pick ourselves. </h5>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. 
              Eum voluptatibus dolores aspernatur animi perferendis iste! 
              Culpa aut enim debitis optio illum ipsum quis perferendis 
              dolor expedita. Similique necessitatibus inventore doloremque.</p>
          <Button link={'/contact-us'} title={'contact us'} />
        </div>
 
      </div> 

    </section>
  )
}

export default About;