import React from 'react';
import './Heading.css';



const Heading = ({title, span}) => {
  return (
    <div className="heading"> 
        <h1>{title}
          <span>{span}</span> 
        </h1> 
    </div>
  )
}

export default Heading