import React from 'react';
import './Products.css';

import { Heading, Product } from '../../../components';
import { productsContent } from '../../../constants';



const Products = () => {

  const size = 8;
  const products = productsContent.slice(0, size);

  return (
    <section className='products'>
        
      <Heading title={'our products'} span={'what we offer'} />

      <div className='box-container'>
      {
          products.map((product) => {
          return( 
            <Product  
            key={product.id}  
            image={product.image}
            name={product.name}
            disprice={product.disprice}
            price={product.price}
            product={product} />
          )
          })
      }
      </div>
      
    </section>
  )
}

export default Products;