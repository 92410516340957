import React from 'react';
import './PageTitle.css';

import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';



const PageTitle = ({title, page}) => {
  return (
    <div className='page-title'>

      <div className='title'>
        <h2>{title}</h2>
      </div>
 
      <div className='link'>
        <Link to='/'>home</Link>
        <BsArrowRight />
        <span className='page'>{page}</span>
      </div>

    </div>
  )
}

export default PageTitle;