import { images } from '.';

export const galleryContent = [
    {
        id: 1,
        image: images.gallery1,
        title: 'red roses',
        category: 'Roses',
        tag: 'Roses',
        link: '#'
    },
    {
        id: 2,
        image: images.gallery2,
        title: 'tibet roses',
        category: 'Roses',
        tag: 'Roses',
        link: '#'
    },
    {
        id: 3,
        image: images.gallery3,
        title: 'pink roses',
        category: 'Roses',
        tag: 'Roses',
        link: '#',
    },
    {
        id: 4,
        image: images.gallery4,
        title: 'Apricot beauty',
        category: 'tulips',
        tag: 'tulips',
        link: '#'
    },
    {
        id: 5,
        image: images.gallery5,
        title: 'spring green',
        category: 'tulips',
        tag: 'tulips',
        link: '#'
    },
    {
        id: 6,
        image: images.gallery6,
        title: 'blue lotus flower',
        category: 'lilies',
        tag: 'lilies',
        link: '#'
    },
    {
        id: 7,
        image: images.gallery7,
        title: 'royal lily',
        category: 'lilies',
        tag: 'lilies',
        link: '#'
    },
    {
        id: 8,
        image: images.gallery8,
        title: 'moth orchid',
        category: 'orchids',
        tag: 'orchids',
        link: '#'
    },
    {
        id: 9,
        image: images.gallery9,
        title: 'vanilla orchid',
        category: 'orchids',
        tag: 'orchids',
        link: '#'
    },
];


