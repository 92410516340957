import { images } from '../constants';


export const categories = [ 
    {
        id: 1,
        title: 'mixed bouquets',
        quantity: '2',
    },
    {
        id: 2,
        title: 'Roses',
        quantity: '3',
    },
    {
        id: 3,
        title: 'tulips',
        quantity: '10',
    },
    {
        id: 4,
        title: 'lilies',
        quantity: '5',
    },
    {
        id: 5,
        title: 'orchids',
        quantity: '15',
    },
    {
        id: 6,
        title: 'peonies',
        quantity: '20',
    },
];
 
export const popular = [
    {
        id: 1,
        image: images.popularProduct1,
        name: 'tulip',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'mix bouquets',
            'roses'
        ],
        tags: [
            'birthday',
            'roses',
            'wedding'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 2,
        image: images.popularProduct2,
        name: 'lily',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'mix bouquets',
            'roses'
        ],
        tags: [
            'birthday',
            'roses',
            'wedding'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
    {
        id: 3,
        image: images.popularProduct3,
        name: 'red roses',
        reviews: '100+', 
        price: '15.00',
        disprice: '12.00',
        availability: 'in stock',
        quantity: 1,
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        category: [
            'mix bouquets',
            'roses'
        ],
        tags: [
            'birthday',
            'roses',
            'wedding'
        ],
        gallery: {
            image1: images.productGallery1,
            image2: images.productGallery2,
            image3: images.productGallery3,
            image4: images.productGallery4,
        }
    },
];

