import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Footer, Header, MobileNavbar, PageTitle, PriceFilter, Product, ShopSidebar } from './components';
import { blogContent } from './constants';
import products from './constants/products';
import { Home, About, Testimonials, Blogs, Contact, Login, Register, ChangePwd, ForgotPwd, Profile, BlogGrid, Gallery, Counter, HomePage, BlogStandard, BlogDetails, ShopGrid, ShopList, ProductDetails, Team, AboutUs, ContactUs, Faqs, TeamPage, TestimonialsPage, BlogList, Wishlist, Checkout, Cart, OrderList, OrderDetails, EditProfile, EditAddress } from './sections';
import { useNavigate } from "react-router-dom";
import { createHashHistory } from 'history'
import ScrollTop from './components/ScrollTop/ScrollTop';



function App() {
  return (
    <div className="App">
    
      
      <Header />
    
      <Routes>
        
        {/*Home*/}
        <Route path='/' element={<HomePage />} />

        {/*About*/}
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/faqs' element={<Faqs />} />
        <Route path='/team' element={<TeamPage />} />
        <Route path='/testimonials' element={<TestimonialsPage />} />

        {/*Pages*/}
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/wishlist' element={<Wishlist />} />
        <Route path='/checkout' element={<Checkout />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/order-list' element={<OrderList />} />
        <Route path='/order-details' element={<OrderDetails />} />
        
        {/*Blogs*/}
        <Route path='/blog-grid' element={<BlogGrid />} />
        <Route path='/blog-list' element={<BlogList />} /> 
        <Route path='/blogs/:id' element={<BlogDetails  />} />
        <Route path='/blog-details' element={<BlogDetails />} />

        {/*Shop*/} 
        <Route path='/shop-grid' element={<ShopGrid />} /> 
        <Route path='/shop-list' element={<ShopList />} />
        <Route path='/shop/:id' element={<ProductDetails />} />
        <Route path='/product-details' element={<ProductDetails />} />

        {/*Account*/}
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/change-pwd' element={<ChangePwd />} />
        <Route path='/forgot-pwd' element={<ForgotPwd />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/edit-profile' element={<EditProfile />} />
        <Route path='/edit-address' element={<EditAddress />} />

        {/*Contact*/}
        <Route path='/contact-us' element={<ContactUs />} />

      </Routes>

      <Footer />
      <ScrollTop />

    </div>

  )
}

export default App;
