import React from 'react';
import './GalleryList.css';
 
const GalleryList = ({id, title, setHandleTag, active}) => {
  return (
    <li
        className={active? 'button active' : 'button'}
        onClick={() => {setHandleTag(id)}}>
        {title}
    </li>
  )
} 

export default GalleryList;