import home1 from '../assets/Home/Home-1.png';
import home2 from '../assets/Home/Home-2.png';
import home3 from '../assets/Home/Home-3.png'; 

import about from '../assets/About/About-Video.mp4'; 


import product1 from '../assets/Products/Product-1.png';
import product2 from '../assets/Products/Product-2.png';
import product3 from '../assets/Products/Product-3.png';
import product4 from '../assets/Products/Product-4.png';
import product5 from '../assets/Products/Product-5.png';
import product6 from '../assets/Products/Product-6.png';
import product7 from '../assets/Products/Product-7.png';
import product8 from '../assets/Products/Product-8.png'; 
import product9 from '../assets/Products/Product-9.png';

import team1 from '../assets/Team/Team-1.png';
import team2 from '../assets/Team/Team-2.png';
import team3 from '../assets/Team/Team-3.png';
import team4 from '../assets/Team/Team-4.png';

import testimonial1 from '../assets/Testimonials/pic-1.png';
import testimonial2 from '../assets/Testimonials/pic-2.png';
import testimonial3 from '../assets/Testimonials/pic-3.png';
import testimonial4 from '../assets/Testimonials/pic-4.png';
import testimonial5 from '../assets/Testimonials/pic-5.png';

import blog1 from '../assets/Blog/Blogs/Blog-1.png';
import blog2 from '../assets/Blog/Blogs/Blog-2.png';
import blog3 from '../assets/Blog/Blogs/Blog-3.png';
import blog4 from '../assets/Blog/Blogs/Blog-4.png';
import blog5 from '../assets/Blog/Blogs/Blog-5.png';
import blog6 from '../assets/Blog/Blogs/Blog-6.png';

import post1 from '../assets/Blog/Recent-Posts/Blog-1.png';
import post2 from '../assets/Blog/Recent-Posts/Blog-2.png';
import post3 from '../assets/Blog/Recent-Posts/Blog-3.png';

import blogGallery1 from '../assets/Blog/Blog-Gallery/1.png';
import blogGallery2 from '../assets/Blog/Blog-Gallery/2.png';
import blogGallery3 from '../assets/Blog/Blog-Gallery/3.png';
import blogGallery4 from '../assets/Blog/Blog-Gallery/4.png';
import blogGallery5 from '../assets/Blog/Blog-Gallery/5.png';
import blogGallery6 from '../assets/Blog/Blog-Gallery/6.png';

import commentsPic1 from '../assets/Blog/Comments/pic-1.png';
import commentsPic2 from '../assets/Blog/Comments/pic-2.png';
import commentsPic3 from '../assets/Blog/Comments/pic-3.png';

import counter from '../assets/Counter/Counter.png';

import gallery1 from '../assets/Gallery/Gallery-1.png';
import gallery2 from '../assets/Gallery/Gallery-2.png';
import gallery3 from '../assets/Gallery/Gallery-3.png';
import gallery4 from '../assets/Gallery/Gallery-4.png';
import gallery5 from '../assets/Gallery/Gallery-5.png';
import gallery6 from '../assets/Gallery/Gallery-6.png';
import gallery7 from '../assets/Gallery/Gallery-7.png';
import gallery8 from '../assets/Gallery/Gallery-8.png';
import gallery9 from '../assets/Gallery/Gallery-9.png';

import productGallery1 from '../assets/Product-Gallery/1.png';
import productGallery2 from '../assets/Product-Gallery/2.png';
import productGallery3 from '../assets/Product-Gallery/3.png';
import productGallery4 from '../assets/Product-Gallery/4.png';

import popularProduct1 from '../assets/Popular/1.png';
import popularProduct2 from '../assets/Popular/2.png';
import popularProduct3 from '../assets/Popular/3.png';

import pageTitle from '../assets/PageTitle/pageTitle-bg.png';

import profile from '../assets/Profile/Profile-Pic.png';





const Images = {
    home1,
    home2,
    home3,

    about,

    product1,
    product2,
    product3,
    product4,
    product5,
    product6,
    product7,
    product8,
    product9,

    team1,
    team2,
    team3,
    team4,

    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,
    testimonial5,

    blog1,
    blog2,
    blog3,
    blog4,
    blog5,
    blog6,

    post1,
    post2,
    post3,

    blogGallery1,
    blogGallery2,
    blogGallery3,
    blogGallery4,
    blogGallery5,
    blogGallery6,

    commentsPic1,
    commentsPic2,
    commentsPic3,

    counter,

    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,

    productGallery1,
    productGallery2,
    productGallery3,
    productGallery4,

    popularProduct1,
    popularProduct2,
    popularProduct3,

    pageTitle,

    profile

};

export default Images;