import React from 'react';
import './MobileNavbar.css';

import { NavbarItem } from '../../components';
import { navbar } from '../../constants';

import { FaTimes } from 'react-icons/fa';

 
 
const MobileNavbar = ({close}) => {
  return (
    <div className='mobile-menu'>
      
        <div id='close-side-bar' onClick={close}><FaTimes /></div>

        <nav className='mobile-navbar'>
        {
            navbar.map((item) => {
                return(
                    <NavbarItem id={item.id} {...item} />
                )
            })
        }
        </nav>
    </div>
  )
}

export default MobileNavbar;