import { images } from "../constants";

export const cartItems = [
    {
        id: 1, 
        image: images.product1,
        name: 'Red roses',
        price: '$50.00',
        quantity: '1',
        total: '$50.00',
    },
    {
        id: 2,
        image: images.product2,
        name: 'Lavender',
        price: '$100.00',
        quantity: '1',
        total: '$100.00',
    },
    {
        id: 3,
        image: images.product3,
        name: 'miniature roses',
        price: '$100.00',
        quantity: '2',
        total: '$200.00',
    },
    {
        id: 4,
        image: images.product4,
        name: 'floribunda',
        price: '$150.00',
        quantity: '2',
        total: '$300.00',
    },
    {
        id: 5,
        image: images.product5,
        name: 'sweatheart roses',
        price: '$150.00',
        quantity: '3',
        total: '$450.00',
    },
];


export const cartSummary = [
    {id: 1, name: 'sub total', value: '$200.00'},
    {id: 2, name: 'delivery fee', value: '$50.00'},
    {id: 3, name: 'discount', value: '$20.00'},
    {id: 4, name: 'tax', value: '$20.00'},
    {id: 5, name: 'total', value: '$250.00'},
];