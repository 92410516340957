import React from 'react';
import './Checkout.css';

import { PageTitle, Heading, ShopSummary } from '../../../components';
import { cartTotal, payment } from '../../../constants';


const Checkout = () => { 
  return (
    <>
      <PageTitle title={'checkout'} page={'checkout'} />
      <section className='checkout'>

        <div className='box-1'>

          <div className='address checkout-item'>
            <Heading title='billing address' />
            <form class="" action="example.php">
              <div class="input-box">
                  <input type="text" name="first-name" placeholder="first name" id="b-first-name" className='box' />
                  <input type="text" name="last-name" placeholder="last name" id="b-last-name" className='box' />
              </div>
              <input type="text" name="company" placeholder="company" id="b-company" className='box' />
              <input type="text" name="country" placeholder="country" id="b-country" className='box' />
              <input type="text" name="address" placeholder="address" id="b-address" className='box' />
              <div class="input-box">
                  <input type="text" name="city" placeholder="town/city" id="b-city" className='box' />
                  <input type="text" name="zip" placeholder="postcode/ZIP" id="b-zip" className='box' />
              </div>
              <div class="input-box">
                  <input type="number" name="number" placeholder="phone no" id="b-number" className='box' />
                  <input type="email" name="email" placeholder="email address" id="b-email" className='box' />
              </div>
            </form>
          </div>

          <div className='address checkout-item'>
            <Heading title='shipping address' />
            <form class="" action="example.php">
              <div class="input-box">
                  <input type="text" name="first-name" placeholder="first name" id="b-first-name" className='box' />
                  <input type="text" name="last-name" placeholder="last name" id="b-last-name" className='box' />
              </div>
              <input type="text" name="company" placeholder="company" id="b-company" className='box' />
              <input type="text" name="country" placeholder="country" id="b-country" className='box' />
              <input type="text" name="address" placeholder="address" id="b-address" className='box' />
              <div class="input-box">
                  <input type="text" name="city" placeholder="town/city" id="b-city" className='box' />
                  <input type="text" name="zip" placeholder="postcode/ZIP" id="b-zip" className='box' />
              </div>
              <div class="input-box">
                  <input type="number" name="number" placeholder="phone no" id="b-number" className='box' />
                  <input type="email" name="email" placeholder="email address" id="b-email" className='box' />
              </div>
            </form>
          </div>

        </div>

        <div className='box-2'>

          <div className='cart-total checkout-item'>
            <Heading title='cart total' />
            <ShopSummary summary={cartTotal} />
          </div>

          <div className='payment-methods checkout-item'>
            <Heading title='payment methods' />
            <div className='box-container'>
              {
                payment.map((item) => {
                  return(
                    <div className='item' key={item.id}>
                      <input type="radio" id={item.paymentID} name="payment" value={item.value}/>
                      <label for={item.paymentID}>{item.title}</label>
                      <div class="payment-body active">
                          <p>{item.content}</p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>

        </div>

      </section>
    </>
  )
}

export default Checkout 