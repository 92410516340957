import React, {useState, useEffect} from 'react';
import './Header.css';

import { HeaderItem, MobileNavbar} from '../../components';
import { navbar } from '../../constants';

import { FaBars, FaUserAlt, FaCartArrowDown } from 'react-icons/fa';
import { GiFlowerPot } from 'react-icons/gi';

import { Link } from "react-router-dom";
 
 
 
const Header = () => { 
  const [click, setClick] = useState(false);

  const handleClick = () => {
    setClick(true);
  }

  const closeMenuBar = () => {setClick(false);}

  /* Manage On Scroll Functionality */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = (e) => {

    /* Close Menubar On Scroll */
    const navbar = document.querySelector('.navbar');
    navbar.classList.remove('active');
    setClick(false);

  };

  return (  

    <nav className='header'>
 
      <Link to='/'  className='logo'>
        <GiFlowerPot className='logo-icon' />
        <h2> Flowery </h2>
      </Link>

      {click && <MobileNavbar close={closeMenuBar} />}

      <div className='navbar' >
        {
          navbar.map((item) => {
            return(
              <HeaderItem id={item.id} {...item} />
            )
          })
        }
      </div>

      <div className='icon-container'>
        <div class="icon menu-btn" id='menu' onClick={handleClick}><FaBars /></div>
        <Link class="icon" id='login' to='/login'><FaUserAlt /></Link>
        <Link class="icon" id='cart' to='/cart'><FaCartArrowDown /></Link>
      </div>

    </nav>
   )
 }
 
 export default Header;