import React, { useState } from 'react';
import './FaqItem.css';

import { FaPlus, FaMinus } from 'react-icons/fa';



const FaqItem = ({id, title, content}) => {

  const [isActive, setIsActive] = useState(false);

  return (
    <div className={isActive ? 'accordion active' : 'accordion'} key={id}>
        <div className='accordion-heading' onClick={() => setIsActive(!isActive)}>
            <h3>{title}</h3>
            <div className='icon'>{isActive ? <FaMinus /> : <FaPlus />}</div>
        </div>
        {isActive && <p className='accordion-content'>{content}</p>}
    </div>
  )
} 

export default FaqItem