import { images } from "../constants";

const wishlist = [
    {
        id: 1, 
        image: images.product1,
        name: 'Red roses',
        price: '$50.00',
        status: 'InStock',
    },
    {
        id: 2,
        image: images.product2,
        name: 'Lavender',
        price: '$50.00',
        status: 'OutOfStock',
    },
    {
        id: 3,
        image: images.product3,
        name: 'miniature roses',
        price: '$50.00',
        status: 'OutOfStock',
    },
    {
        id: 4,
        image: images.product4,
        name: 'floribunda',
        price: '$50.00',
        status: 'InStock',
    },
    {
        id: 5,
        image: images.product5,
        name: 'sweatheart roses',
        price: '$50.00',
        status: 'InStock',
    },
];

export default wishlist;