import React from 'react';
import './BlogList.css';

import { PageTitle, BlogSidebar, Blog, PagesNo } from '../../../components';
import { blogContent } from '../../../constants';



const BlogList = () => {

  const size = 4;
  const blogs = blogContent.slice(0, size);
  
  return (
    <>
      <PageTitle title={'blog list'} page={'blog list'} />
      <section className='blog list'>
        <BlogSidebar />
        <div className='blog-container list'>
          {
            blogs.map((blog) => {
              return( 
                <Blog 
                  key={blog.id} 
                  image={blog.image}
                  heading={blog.heading}
                  content={blog.content} 
                  blog={blog} 
                />)
            })
          }
          <PagesNo />
        </div>
      </section>
    </>

  )
}

export default BlogList;