import { images } from '../constants';

const home = [
    { 
        id: 1,
        image: images.home1,
        title: 'Fresh flowers for every occasion',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'The best gift for a great day',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi.',   },
    { 
        id: 3,
        image: images.home3,
        title: 'We’ve got all your bouquets covered.',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi.',       
    },

];

export default home;