import React from 'react';
import {Home, About, Counter, Team, Testimonials, Blogs} from '../../sections'; 
import Products from './Products/Products';
 
const HomePage = () => {
  return (
      <div className='homepage'>
        <Home />
        <About />
        <Counter />
        <Products />
        <Team />
        <Testimonials />
        <Blogs />
      </div>
  )
}

export default HomePage;