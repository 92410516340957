import React from 'react';
import './BlogInfo.css';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaUserAlt } from 'react-icons/fa';
import { BsClock } from 'react-icons/bs';
import { FiMessageCircle } from 'react-icons/fi';



const BlogInfo = (props) => {
        
    const {blog} = props;
    const paragraphs = blog.paragraph; 

    return(
    <div className='blog-info' key={blog.id}>
        <div className='image'>
            <span className='date'>{blog.date}</span>
            <img src={blog.image} alt='Blog' />
        </div>
        <div className='content'>

            <h3 class="main-heading">{blog.heading}</h3>

            <div class="details"> 
                <h5 class="admin"><FaUserAlt className='icon' /><span>{blog.admin}</span></h5>
                <h5 class="read"><BsClock className='icon' /><span>{blog.mins} mins read</span></h5>
                <h5 class="comment"><FiMessageCircle className='icon' /><span>{blog.commentsQty} comments</span></h5>
            </div>

            <p>{paragraphs.para1}</p>  
            <p>{paragraphs.para2}</p> 
            
            <div class="important">
                {blog.important}
            </div>

            <p>{paragraphs.para3}</p>  
            <p>{paragraphs.para4}</p> 

            <div class="blog-gallery">
                {
                    (blog.gallery).map((item) => {
                        return <img src={item.image} alt='' />
                    })
                }
            </div> 

            <p>{paragraphs.para5}</p>  
            <p>{paragraphs.para6}</p>  

            <div class="end-details">
                <div class="tags">
                    <h3>tags:</h3>
                    {
                        (blog.tags).map((item) => {
                            return <span>{item.title}</span>
                        })
                    }
                </div>

                <div class="share">
                    <h3>share:</h3>
                    <FaFacebookF className='icon' />
                    <FaLinkedin className='icon' />
                    <FaTwitter className='icon' />
                    <FaInstagram className='icon' />
                </div>
            </div>
        </div>
    </div>
    )
}

export default BlogInfo;