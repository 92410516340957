import React from 'react';
import './Cart.css';

import { PageTitle, Button, CartItem, ShopSummary } from '../../../components';
import { cartItems, cartSummary } from '../../../constants';



const Cart = () => {

  return ( 
    <> 
      <PageTitle title={'cart'} page={'cart'} />
      <section className='cart'>

        <div className='shopping-cart'>
          
          <div className='container'>

            <div className='title'>
              <h3>product</h3>
              <h3>name</h3>
              <h3>price</h3>
              <h3>quantity</h3>
              <h3>total</h3>
              <h3>activity</h3>
            </div>

            <div className='box-container'>
              { 
                cartItems.map((item) => {
                  return(
                    <CartItem 
                    id={item.id}
                    image={item.image}
                    name={item.name}
                    price={item.price}
                    quantity={item.quantity}
                    total={item.total}
                    />
                  )
                })
              }
            </div>

          </div>
        </div>

        <div className='cart-total'>
          <ShopSummary summary={cartSummary} />
          <Button link={'/checkout'} title={'proceed to checkout'} />
        </div>

      </section>
    </>
  )
}

export default Cart