import React from 'react';
import './Blog.css';

import { Link } from 'react-router-dom';


const Blog = (props) => {
  const {image, heading, content, blog} = props;
  return ( 
    <div className='blog-item'>

        <div className='image'>
          <img src={image} alt='Blog' />
        </div>

        <div className='content'>
          <Link to={{
            pathname: '/Blogs/' + heading,
          }} 
          state= {blog}
          className='main-heading'>{heading}
          </Link>
          <p>{content}</p>    
        </div>

    </div>
  )
}

export default Blog;