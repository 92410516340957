import products from "./products";
import blogs from './blogs';

const navbar = [
    {
        id: 1,
        mainLink : {link: '/', title: 'home'},
        subLink: [],
    },
    {
        id: 2,
        mainLink : {link: '#', title: 'about'},
        subLink: [
            {id: 1, link: '/about-us', title: 'about us'},
            {id: 2, link: '/faqs', title: 'faqs'},
            {id: 3, link: '/team', title: 'team'},
            {id: 4, link: '/testimonials', title: 'testimonials'}
        ],
    },
    {
        id: 3,
        mainLink : {link: '#', title: 'pages'},
        subLink: [
            {id: 1, link: '/gallery', title: 'Gallery'},
            {id: 2, link: '/wishlist', title: 'wishlist'},
            {id: 3, link: '/checkout', title: 'checkout'},
            {id: 4, link: '/cart', title: 'cart'},
            {id: 5, link: '/order-list', title: 'order list'},
            {id: 6, link: '/order-details', title: 'order details'},
        ],
    }, 
    {
        id: 4,
        mainLink : {link: '#', title: 'blog'},
        subLink: [
            {id: 1, link: '/blog-grid', title: 'blog grid', state: null},
            {id: 2, link: '/blog-list', title: 'blog list', state: null},
            {id: 3, link: '/blog-details', title: 'blog details', state: blogs[0]},
        ],
    },
    {
        id: 5,
        mainLink : {link: '#', title: 'shop'},
        subLink: [
            {id: 1, link: '/shop-grid', title: 'shop grid', state: null},
            {id: 2, link: '/shop-list', title: 'shop list', state: null},
            {id: 3, link: '/product-details', title: 'product details', state: products[0]},
        ],
    },
    {
        id: 6,
        mainLink : {link: '#', title: 'account'},
        subLink: [
            {id: 1, link: '/login', title: 'login'},
            {id: 2, link: '/register', title: 'register'},
            {id: 3, link: '/change-pwd', title: 'changePwd'},
            {id: 4, link: '/forgot-pwd', title: 'forgotPwd'},
            {id: 5, link: '/profile', title: 'profile'},
            {id: 6, link: '/edit-profile', title: 'edit profile'},
            {id: 7, link: '/edit-address', title: 'edit Address'},
        ],
    },
    {
        id: 7,
        mainLink : {link: '/contact-us', title: 'contact'},
        subLink: [],
    },
];

export default navbar;