import React from 'react';
import './Product.css'; 

import { FaStar } from 'react-icons/fa';
import { BsCartPlusFill } from 'react-icons/bs';
import { FiHeart } from 'react-icons/fi';
import { AiOutlineEye } from 'react-icons/ai';

import { Link } from 'react-router-dom';

 

const Product = ({image, name, product, content, disprice, price}) => { 

  return (
    <div className='product-item'>
      <div className='options'>
        <Link to='/cart'><BsCartPlusFill className='icon' /></Link>
        <Link to='/wishlist'><FiHeart className='icon' /></Link>
        <Link to='/product-details'><AiOutlineEye className='icon' /></Link>
      </div>
      <div className='image'>
          <div className='options'></div>
          <img src={image} alt='' />
      </div>
      <div className='content'>
        <Link to={{
          pathname: '/Shop/' + name,
        }} 
        location = {{product}}
        state= {product}><h3>{name}</h3>
        </Link>
          <div className='rating'>
            <FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
          </div>
          <p>{content}</p>
          <div className='price'>
              <p>{disprice} <span>{price}</span></p>
          </div>
      </div>
    </div> 
  )
} 

export default Product;