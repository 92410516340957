import React from 'react';
import './ShopSidebar.css';

import { SidebarHeading, PriceFilter, Product } from '../../components';
import { popularProducts, shopCategories } from '../../constants';



const ShopSidebar = () => {
  return (
      <> 
        <div className='shop-sidebar'>
            
            <div class="category sidebar-item">
                <SidebarHeading title='categories' />
                <div className='box-container'>
                    {
                        shopCategories.map((category) => {
                            return(
                            <div className='item' key={category.id}>
                                <a href='#'>{category.title}</a>
                                <p>({category.quantity})</p>
                            </div>
                            ) 
                        })
                    }
                </div>
            </div>

            <div className='filter sidebar-item'>

                <SidebarHeading title='filter' />
                <PriceFilter />

            </div>

            <div className='popular sidebar-item'>
                <SidebarHeading title='popular products' />
                <div className='box-container'>
                {
                    popularProducts.map((product) => {
                        return(
                            <Product  
                            key={product.id} 
                            image={product.image}
                            name={product.name}
                            disprice={product.disprice}
                            price={product.price}
                            product={product} />
                          )
                        }) 
                }
                </div> 
            </div>

        </div>
      </>
  )
}

export default ShopSidebar;