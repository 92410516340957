import React from 'react';
import './Counter.css';

import { BsPeopleFill, BsFillBagCheckFill } from 'react-icons/bs';
import { GiVineFlower } from 'react-icons/gi';
import { RiEmotionHappyLine } from 'react-icons/ri';

import CountUp from 'react-countup';


const Counter = () => {
  return ( 
    <div class="counting">

        <div class="box">
            <BsFillBagCheckFill className='icon' />
            <CountUp class="count" end={50} duration={3} suffix={'+'} />
            <h3>products</h3>
        </div>
        
        <div class="box">
            <RiEmotionHappyLine className='icon' />
            <CountUp class="count" end={500} duration={3} suffix={'+'} />
            <h3>Satisfied Clients</h3>
        </div> 

        <div class="box">
            <GiVineFlower className='icon' />
            <CountUp class="count" end={300} duration={3} suffix={'+'} />
            <h3>Flower Arrangements</h3>
        </div>
                    
        <div class="box">
            <BsPeopleFill className='icon' />
            <CountUp class="count" end={20} duration={3} suffix={'+'} />
            <h3>Staff</h3>
        </div>

    </div>
  )
}

export default Counter;