import React from 'react';
import './Footer.css';

import { galleryContent } from '../../constants'; 

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai'; 
import { BsMapFill, BsArrowRight } from 'react-icons/bs';

import { Link } from 'react-router-dom';


  
const Footer = () => { 

  const size = 6;
  const gallery = galleryContent.slice(0, size);
  const gallery1 = gallery.slice(0, 3);
  const gallery2 = gallery.slice(3, size);
  
  return (
    <footer className='footer'>

      <div className='box-container'>

        <div className='footer-item'>
          <h2>contact info</h2>
          <div className='info'>
            <p><AiTwotonePhone className='icon' /><span>+111-222-333</span></p>
            <p><AiTwotonePhone className='icon' /><span>+123-456-789</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">abc@gmail.com</span></p>
            <p><FaEnvelope className='icon' /><span class="gmail">xyz@gmail.com</span></p>
            <p><BsMapFill className='icon' /><span>karachi, pakistan</span></p>
          </div>
          <div class="social">
            <a href="/#"><FaFacebookF className='icon' /></a>
            <a href="/#"><FaTwitter className='icon' /></a>
            <a href="/#"><FaInstagram className='icon' /></a>
            <a href="/#"><FaLinkedin className='icon' /></a>
          </div>
        </div>

        <div className='footer-item'>
          <h2>quick links</h2>
          <div className='info links'>
            <p><BsArrowRight className='icon' /><Link to='/'>home</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/about-us'>about</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/blog-grid'>blog</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/gallery'>gallery</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/shop-grid'>shop</Link></p>
            <p><BsArrowRight className='icon' /><Link to='/contact-us'>contact</Link></p>
          </div>
        </div> 

        <div className='footer-item'>
          <h2>gallery</h2>
          <div className='footer-gallery'>
            <div className='row-1'>
            {
              gallery1.map((item) => {
                return(
                  <div className='image' key={item.id}>
                    <img src={item.image} alt></img>
                  </div>
                )
              })
            } 
            </div>
            <div className='row-2'>
            {
              gallery2.map((item) => {
                return(
                  <div className='image' key={item.id}>
                    <img src={item.image} alt></img>
                  </div>
                )

              })
            } 
            </div>

          </div>
        </div>

      </div>

      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;